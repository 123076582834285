a {
    color: black;
}

.nav .nav-link:hover,
.nav .nav-link:focus {
    color: #2377bd;
}

.nav .nav-item .nav-link {
    color: black;
    font-weight: 600;
}

.form-control,
.form-select {
    border: 1px solid #6c6c6c;
}

.filter-text {
    padding: 3px 10px;
}

.dt-buttons {
    margin-bottom: 15px;
}

.tracking-page-card {
    background: #ffffffdb;
    width: 100%;
}

.search-form {
    width: 50%;
}

.search-result {
    width: 98%;
}

.apexcharts-legend-text {
    font-size: 13px !important;
}

.badge-info {
    background: #03c3ec;
}

.css-13cymwt-control {
    border-color: #000 !important;
}

#clients tbody td {
    padding: 5px !important;
}

.text-black {
    color: #000 !important;
}

.form-control:focus,
.form-select:focus {
    border-color: #2377bd !important;
}

.btn-primary {
    color: #fff;
    background-color: #2377bd;
    border-color: #2377bd;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(35 119 189);
}

.btn-primary:hover {
    color: #fff;
    background-color: #228ee7;
    border-color: #228ee7;
    transform: translateY(-1px);
}

.text-primary {
    color: #2377bd !important;
}

.search-form .form-label {
    color: #000 !important;
}

input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    color: #fff;
    position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
    position: absolute !important;
    border-left: 1px solid #8c8c8c;
    padding: 2px;
    color: #000;
    left: 56px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
    position: absolute !important;
    border-left: 1px solid #8c8c8c;
    padding: 2px;
    color: #000;
    left: 26px;
}


input[type="date"]::-webkit-datetime-edit-day-field {
    position: absolute !important;
    color: #000;
    padding: 2px;
    left: 4px;

}

@media screen and (max-width: 600px) {
    .search-form {
        width: 90%;
    }

    .search-result {
        width: 90%;
    }

    .tracking-page-card {
        padding: 15px;
    }
}