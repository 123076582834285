.timeline-steps {
    display: flex;
    flex-direction: row;
}

.timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.6rem
}

.stage-item {
    border-right: 1px solid #000;
    background-color: beige;
}

.stage-item:last-child {
    border-right: none;
    /* Remove border for the last stage item */
}

@media (min-width:800px) {
    .timeline-steps .timeline-step:not(:last-child):after {
        content: "";
        display: block;
        border-top: 0.25rem dotted #8592a3;
        width: 2.3rem;
        position: absolute;
        left: 6.2rem;
        top: 0.5rem;
    }

    .timeline-steps .timeline-step:not(:first-child):before {
        content: "";
        display: block;
        border-top: 0.25rem dotted #8592a3;
        width: 2.3rem;
        position: absolute;
        right: 6.2rem;
        top: 0.5rem;
    }
}

.timeline-steps .timeline-content {
    width: 8rem;
    text-align: center
}

.timeline-steps .timeline-content .inner-circle {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.timeline-steps .timeline-content .inner-circle:before {
    background-color: #71dd37;
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    border-radius: 6.25rem;
    opacity: .5
}

.timeline-steps .timeline-content .inner-circle::before {
    content: "\2713";
    /* Insert tick mark character */
    font-size: 1.5rem;
    /* Adjust font size as needed */
    color: #fff;
    /* Set tick mark color */
    font-weight: bold;
    /* Optionally set font weight */

}

.timeline-steps .timeline-content .inner-circle-gray {
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    /* Use your desired gray color */
}

.timeline-steps .timeline-content .inner-circle-gray:before {
    content: "";
    background-color: #ccc;
    /* Use your desired gray color */
    display: inline-block;
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    border-radius: 6.25rem;
    opacity: .5
}

.timeline-duration {
    font-size: 12px;
    color: #6b7280;
    margin-top: 4px;
}

ul.progress-bar {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
}

li.section {
    display: inline-block;
    padding-top: 45px;
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    color: gray;
    vertical-align: top;
    position: relative;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

li.section:before {
    content: 'x';
    position: absolute;
    top: 3px;
    left: calc(50% - 15px);
    z-index: 1;
    width: 30px;
    height: 30px;
    color: white;
    border: 2px solid white;
    border-radius: 17px;
    line-height: 25px;
    background: gray;
}

.status-bar {
    height: 2px;
    background: gray;
    position: relative;
    top: 20px;
    margin: 0 auto;
}

.current-status {
    height: 2px;
    width: 0;
    border-radius: 1px;
    background: mediumseagreen;
}

.progress-bar-wrapper {
    position: relative;
}

@keyframes changeBackground {
    from {
        background: gray
    }

    to {
        background: mediumseagreen
    }
}

li.section.visited:before {
    content: '\2714';
    animation: changeBackground .5s linear;
    animation-fill-mode: forwards;
}

li.section.visited.current:before {
    box-shadow: 0 0 0 2px mediumseagreen;
}

li.section {
    width: 16.6667%;
    /* Adjust width for each stage */
    text-align: center;
}

/* Add this media query for mobile */
@media (max-width: 800px) {
    .timeline-steps {
        display: flex;
        flex-direction: column;
    }

    ul.progress-bar {
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 0;
        list-style: none;
        display: flex;
        /* Display stages horizontally */
        justify-content: space-between;
        /* Distribute stages evenly */
    }

    .tracking-progress-bar {
        flex-direction: column;
    }

    li.section {
        width: 100%;
        /* Adjust width for each stage */
        text-align: center;
    }
}