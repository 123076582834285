@keyframes dash {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.steps-container {
  display: flex;
  height: 80px;
  width: 100%;
  gap: 6px;
}

.step-item {
  background: #dfdfdf;
  border-radius: 15px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.step-item .icon {
  align-self: center;
}

.step-item.active {
  background: #2e8300;
  color: #fff;
}

.step-item.loading {
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: black;
  color: #fff;
}

.step-item.loading:before,
.step-item.loading:after,
.step-item.loading span {
  display: flex;
  position: absolute;
  transform: translateX(-100%);
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.step-item.loading span {
  animation: dash 1.618s infinite cubic-bezier(0.4, 0, 1.0, 1.0);
  background-color: #00bc8b;
  z-index: 2;
}

.step-item.loading span:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #dfdfdf;
}

.step-item.loading:before {
  content: '';
  animation: dash 1.618s infinite cubic-bezier(0.2, 0, 1.0, 1.0);
  background-color: #9eddcc87;
  z-index: 1;
}

.step-item.loading:after {
  content: '';
  animation: dash 1.618s infinite linear;
  background-color: #cbf4e9;
  z-index: 0;
}

@media (max-width: 800px) {
  .steps-container {
   flex-direction: column;
   height: auto;
  }
}